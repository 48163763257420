import { ReactNode } from 'react';

import styles from './Card.module.css';

type CardProps = {
  className?: string;
  children: ReactNode;
};

function Card({ className = '', children }: CardProps) {
  return <div className={`${styles.CardContainer} ${styles.CardBackground} ${className}`}>{children}</div>;
}

export default Card;
